const ProcessType = {
    OffShore: [
        {
            title: 'ContactSpecialist',
            icon: 'step_1',
            splitLine: true,
            key: '1'
        },
        {
            title: 'CompanyName',
            icon: 'step_2',
            splitLine: true,
            key: '2'
        },
        {
            title: 'ProvideFile',
            icon: 'step_3',
            splitLine: true,
            key: '3'
        },
        {
            title: 'RegistrationSuccess',
            icon: 'step_4',
            key: '4'
        }
    ],
    Bank: [
        {
            title: 'ContactSpecialist',
            icon: 'step_1',
            splitLine: true,
            key: '1'
        },
        {
            title: 'AddressBank',
            icon: 'step_2',
            splitLine: true,
            key: '2'
        },
        {
            title: 'ProvideFile',
            icon: 'step_3',
            splitLine: true,
            key: '3'
        },
        {
            title: 'SuccessAccount',
            icon: 'step_4',
            key: '4'
        }
    ],
    Audit: [
        {
            title: 'ContactSpecialist',
            icon: 'step_1',
            splitLine: true,
            key: '1'
        },
        {
            title: 'RenewCompany',
            icon: 'step_2',
            splitLine: true,
            key: '2'
        },
        {
            title: 'PayAnnualFee',
            icon: 'step_3',
            splitLine: true,
            key: '3'
        },
        {
            title: 'ReviewSuccess',
            icon: 'step_4',
            key: '4'
        }
    ]
};

export default ProcessType;
