import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import c from 'classnames';
import Icon from '../IconFont';
import style from './style.module.less';

const partnerList2: Array<{ href: string; img: string }> = [
    {
        href: 'https://www.facebook.com/FinovyConsulting',
        img: '/static/footer/facebook.webp'
    },
    {
        href: 'https://www.instagram.com/finovyconsulting',
        img: '/static/footer/linkedin.webp'
    },
    {
        href: 'https://www.linkedin.com/in/finovyconsulting',
        img: '/static/footer/instagram.webp'
    },
    {
        href: 'https://twitter.com/finovyconsult',
        img: '/static/footer/twitter.webp'
    }
];

const partnerList: Array<{ href: string; img: string }> = [
    {
        href: 'https://www.zhihu.com/org/finovy-visa',
        img: '/static/footer/zhihu.svg'
    },
    {
        href: 'https://mp.sohu.com/profile?xpt=ZGNhMGI3YzctN2VkMi00YTZkLWIzZWUtNmQ4YmQwYWRiNjQx',
        img: '/static/footer/sohu.svg'
    },
    {
        href: 'https://author.baidu.com/home/1715940218179418',
        img: '/static/footer/baijia.svg'
    },
    {
        href: 'https://www.toutiao.com/c/user/token/MS4wLjABAAAAh5JiNpiQ-tl9DfZC7_drXVB_tcu_bK3qEYwjNPzlZDuajN15QlQbJYkDG9pBVW-d/?source=list&log_from=fd3ee867f4766_1665568946099',
        img: '/static/footer/headlines.svg'
    }
];
interface Protocol {
    id: string;
    text: string;
    url: string;
}

const MenuListTwo = [
    {
        name: 'Menu.HKTalentsProgram',
        url: '/services/hongkongym'
    },
    {
        name: 'Menu.HKMainlandTalentsProgram',
        url: '/services/hongkongzc'
    },
    {
        name: 'Menu.HKTopTalentsProgram',
        url: '/services/hongkonggc'
    },
    {
        name: 'Menu.HongKongCapitalInvestorEntryScheme',
        url: '/services/cies'
    },
    {
        name: 'Menu.TurkeyInvestmentImmigration',
        url: '/services/turkeyym'
    },
    {
        name: 'Menu.DubaiGoldenVisa',
        url: '/services/dubaihjqz'
    },
    // {
    //     name: 'Menu.VanuatuPassport',
    //     url: '/services/vanuatuym'
    // },
    // {
    //     name: 'Menu.PortugalHousePurchaseImmigration',
    //     url: '/services/portugalym'
    // },
    {
        name: 'Menu.DubaiWorkTouristVisa',
        url: '/services/dubaiqz'
    },
    // {
    //     name: 'Menu.ThailandEliteVisa',
    //     url: '/services/thailandqz'
    // },
    {
        name: 'Menu.SingaporeWorkPermit',
        url: '/services/singaporeqz'
    },
    {
        name: 'Menu.MauritiusSuperVisa',
        url: '/services/mauritiusqz'
    }
];

const MenuListThree = [
    {
        name: 'Menu.AccountingFinancialReporting',
        url: '/services/accounting'
    },
    {
        name: 'Menu.TaxAdvisoryCompliance',
        url: '/services/tax'
    }
];
const FooterInfo: React.FC = () => {
    const file_cdn = '/oss-service';

    const [menuListOne] = useState([
        {
            name: intl.get('Menu.OverseasRegistration'),
            url: '/services/offshore'
        },
        {
            name: intl.get('Menu.OverseasBankAccount'),
            url: '/services/bank'
        },
        {
            name: intl.get('Menu.AuditService'),
            url: '/services/audit'
        },
        {
            name: intl.get('Menu.InternationalCertificationNotarization'),
            url: '/services/notarization'
        },
        {
            name: intl.get('Menu.ChinaCommissionedNotarization'),
            url: '/services/chinanotary'
        }
    ]);
    const [menuListTwo] = useState(MenuListTwo);
    const [menuListThree] = useState(MenuListThree);
    const [protocolList] = useState<Protocol[]>([
        {
            id: '1',
            text: intl.get('Protocol.ServiceAgreement'),
            url: '/static/policy/2021.10.08-ProfessionalServiceAgreement.pdf'
        },
        {
            id: '2',
            text: intl.get('Protocol.MutualNDA'),
            url: '/static/policy/2021.10.08-MutualNon-DisclosureAgreement(FinovyConsulting).pdf'
        },
        {
            id: '3',
            text: intl.get('Protocol.PrivacyPolicy'),
            url: '/static/policy/Finovy-PrivacyPolicy.pdf'
        },
        {
            id: '4',
            text: intl.get('Protocol.Terms'),
            url: '/static/policy/Finovy-WebsiteTermsOfUse.pdf'
        }
    ]);

    // const changeRouter = (lang: string) => {
    //     switch (lang) {
    //         case 'zh_CNS':
    //             return 'sc';
    //         case 'zh_CNT':
    //             return 'tc';
    //         case 'en_US':
    //             return 'en';
    //     }
    // };

    return (
        <>
            <div className={style['free-consult']} style={{ backgroundImage: `url(${file_cdn}/static/footer/free-consult-bg.jpg)` }}>
                <div className={style['free-content']}>
                    <p className={style['free-text']}>
                        {intl.get('Protocol.Expansion')}
                        <br />
                        {intl.get('Protocol.BestPartner')}
                    </p>
                    <div className={style['free-consult-btn']}>
                        <Link href="/contact">
                            <span className={style.fileInfo}>{intl.get('Protocol.FreeConsult')}</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={style.footerContainer} style={{ backgroundImage: `url('${file_cdn}/static/footer/footer-bg.svg')` }}>
                <div className={style.footerInfo}>
                    <div className={style['footer-logo']}>
                        <img loading="lazy" src={`${file_cdn}/static/footer/logo-white.svg`} alt="" />
                    </div>
                    <LazyLoad>
                        <div className={style['footer-content']}>
                            <div className={style['footer-left']}>
                                <span className={style['contact-us']}>{intl.get('Menu.ContactUs')}</span>
                                <div className={style['footer-contact']}>
                                    <div className={style['code-box']}>
                                        <img loading="lazy" src={`${file_cdn}/static/footer/thePublic.jpg`} alt="" />
                                        <span>{intl.get('Protocol.OfficialPublicAccount')}</span>
                                    </div>
                                    <div className={style['code-box']}>
                                        <img loading="lazy" src={`${file_cdn}/static/footer/enterpriseWeChat.jpg`} alt="" />
                                        <span>{intl.get('Protocol.EnterpriseWeChat')}</span>
                                    </div>
                                </div>
                                <div className={style['partner-connect']}>
                                    {partnerList2.map((item) => (
                                        <img
                                            loading="lazy"
                                            key={item.img}
                                            src={`${file_cdn}${item.img}`}
                                            alt=""
                                            onClick={() => {
                                                const tempWindow = window.open('_blank');
                                                tempWindow.location.href = item.href;
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className={c(style['partner-connect'], style['p-m'])}>
                                    {partnerList.map((item) => (
                                        <img
                                            loading="lazy"
                                            key={item.img}
                                            src={`${file_cdn}${item.img}`}
                                            alt=""
                                            onClick={() => {
                                                const tempWindow = window.open('_blank');
                                                tempWindow.location.href = item.href;
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className={style.serviceTime}>{intl.get('Protocol.WorkingHours')}</div>
                                <div className={style.phone}>
                                    <Icon type="phone" title="phone" className={style['phone-icon']} />
                                    <span className={style['phone-text']}>{intl.get('Protocol.Telephone')}</span>
                                </div>
                                <div className={style.phone}>
                                    <Icon type="mark" title="mark" className={style['phone-icon']} />
                                    <span className={style['phone-text']}>{intl.get('Protocol.Address')}</span>
                                </div>
                                <div className={style.copyRight}>
                                    {intl.get('Protocol.CopyRightDesc')}
                                    <br />
                                    {intl.get('Protocol.CopyRight')}
                                </div>
                                <div className={style.copyInfo}>
                                    {protocolList.map((item, index) => (
                                        <div key={item.id} className={style['copy-info-flex']}>
                                            {index > 0 && <span className={style['file-line']}>&ensp;|&ensp;</span>}
                                            <span
                                                className={style.fileInfo}
                                                onClick={() => {
                                                    const tempWindow = window.open('_blank');
                                                    tempWindow.location.href = item.url;
                                                }}
                                            >
                                                {item.text}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={style['footer-right']}>
                                <div className={style['footer-nav']}>
                                    <div className={style['footer-nav-fisrt']}>
                                        <p className={style['footer-nav-tit']}>{intl.get('Menu.ServiceArea')}</p>
                                        <div className={style['footer-right-box']}>
                                            <div className={style['nav-item']}>
                                                <p className={style['nav-tit']}>{intl.get('Menu.CompanySecretary')}</p>
                                                <div className={style['nav-line']} />
                                                <ul className={style['footer-nav-list']}>
                                                    {menuListOne.map((item) => (
                                                        <li key={item.url}>
                                                            <Link href={item.url}>
                                                                <span className={style.fileInfo}>{item.name}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className={style['nav-item']}>
                                                <p className={style['nav-tit']}>{intl.get('Menu.ImmigrantVisa')}</p>
                                                <div className={style['nav-line']} />
                                                <ul className={style['footer-nav-list']}>
                                                    {menuListTwo.map((item) => (
                                                        <li key={item.url}>
                                                            <Link href={item.url}>
                                                                <span className={style.fileInfo}>{intl.get(item.name) || item.name}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className={style['nav-item']}>
                                                <p className={style['nav-tit']}>{intl.get('Menu.FinancialManagement')}</p>
                                                <div className={style['nav-line']} />
                                                <ul className={style['footer-nav-list']}>
                                                    {menuListThree.map((item) => (
                                                        <li key={item.url}>
                                                            <Link href={item.url}>
                                                                <span className={style.fileInfo}>{intl.get(item.name)}</span>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style['foot-nav-two']}>
                                        <p className={style['footer-nav-tit']}>{intl.get('Protocol.ServiceRules')}</p>
                                        <ul className={style['footer-nav-list']}>
                                            <li>
                                                <span
                                                    className={style.fileInfo}
                                                    onClick={() => {
                                                        const tempWindow = window.open('_blank');
                                                        tempWindow.location.href = '/static/policy/BankAccountList.pdf';
                                                    }}
                                                >
                                                    {intl.get('Protocol.PayMethods')}
                                                </span>
                                            </li>
                                            <li style={{ color: ' rgba(255, 255, 255, 0.65)' }}>&nbsp;&nbsp;|&nbsp;&nbsp;</li>
                                            <li>
                                                <Link href="/pricing">
                                                    <span className={style.fileInfo}>{intl.get('Menu.Pricing')}</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LazyLoad>
                </div>
            </div>
        </>
    );
};

export default FooterInfo;

FooterInfo.defaultProps = {
    isMobile: false
};
