import fetch from 'cross-fetch';
import intl from 'react-intl-universal';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { IncomingMessage } from 'http';
import { message } from 'antd';
import errorCodes from './error-code';

type Method = 'GET' | 'POST' | 'UPLOAD';

interface ResponseContent {
    code: number | string;
    data: any;
}

interface FetchParam {
    info: string;
    init: RequestInit;
}

interface ContainerInfo {
    listeningPort?: string;
}

const getFetchParams = (req: IncomingMessage & ContainerInfo, method: Method, url: string, data?: any, isCdnSource?: boolean): FetchParam => {
    let reqUrl = isCdnSource ? url : `/api/${url}`;
    if (req) {
        const port = req.listeningPort;
        // 服务端渲染直接使用 localhost 访问接口
        reqUrl = `http://localhost:${port}/api/${url}`;
    }
    switch (method) {
        case 'GET':
            return {
                info: encodeURI(
                    `${reqUrl}?${Object.entries(data || {})
                        .filter(([, value]) => typeof value === 'number' || !!value)
                        .map(([key, value]) => `${key}=${typeof value === 'object' ? JSON.stringify(value) : value}`)
                        .reduce((prev: any, curr: any) => `${prev}&${curr}`, '')}`
                ),
                init: {
                    credentials: 'same-origin',
                    headers: req
                        ? {
                              cookie: (req.headers && req.headers.cookie) || null
                          }
                        : null
                }
            };
        case 'POST':
            return {
                info: encodeURI(reqUrl),
                init: {
                    method: 'POST',
                    headers: {
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        ...(req && req.headers && req.headers.cookie ? { cookie: req.headers.cookie } : {})
                    },
                    credentials: 'same-origin',
                    body: JSON.stringify(data)
                }
            };
        case 'UPLOAD':
            return {
                info: encodeURI(reqUrl),
                init: {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json'
                    },
                    credentials: 'include',
                    body: Object.entries(data).reduce((formData: any, [key, value]) => {
                        formData.append(key, value);
                        return formData;
                    }, new FormData())
                }
            };
    }
};

const whiteList = ['user/profile'];

class MyRequest {
    private method: Method;

    private url: string;

    private data: any;

    private isCdnSource: boolean;

    constructor(method: Method, url: string, data?: any, isCdnSource?: boolean) {
        this.method = method;
        this.url = url;
        this.data = data;
        this.isCdnSource = isCdnSource;
    }

    fetch(req?: IncomingMessage) {
        const { method, url, data, isCdnSource } = this;
        const { info, init } = getFetchParams(req, method, url, data, isCdnSource);
        const result = from(
            fetch(info, init)
                .then((res: Response) => (res.ok ? res.json() : { code: res.status }))
                .then((res: ResponseContent) => {
                    const { code, data: results } = res;
                    // 返回正常响应数据，cdn 静态资源直接返回 res
                    if (!code || code === 200) return isCdnSource ? res : results;
                    throw res;
                })
                .catch((err) => {
                    if (whiteList.includes(url) || isCdnSource) return;
                    if (err && err.code) {
                        const { code } = err;
                        if (process.browser) {
                            message.error(errorCodes[code as number] ? intl.get(errorCodes[code as number]) : intl.get(errorCodes[1000]));
                        }
                    } else {
                        process.browser && message.error(intl.get(errorCodes[1000]));
                    }
                    throw err;
                })
        ).pipe(first());
        return result;
    }
}

const createFetchFunc = (method: Method, isCdnSource?: boolean) => (url: string, data?: any) =>
    isCdnSource ? new MyRequest(method, url, {}, true) : new MyRequest(method, url, data);

const get = createFetchFunc('GET');
const getSource = createFetchFunc('GET', true);
const post = createFetchFunc('POST');
const upload = createFetchFunc('UPLOAD');

export default { get, getSource, post, upload };
