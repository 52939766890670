import React from 'react';
import intl from 'react-intl-universal';
import style from '../style.module.less';

const StepOne: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('OffShore.SuggestInfo')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('OffShore.SuggestChoose')}</span>
        </p>
    </>
);

const StepTwo: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('OffShore.NeedProvide')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('OffShore.PersonalID')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('OffShore.AddressFile')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('OffShore.OtherFile')}</span>
        </p>
    </>
);

const StepThree: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('OffShore.SuccessInfo')}</span>
        </p>
    </>
);

export default { StepOne, StepTwo, StepThree };
