import React from 'react';
import { Input, Button, Spin } from 'antd';
import CaptchaApi from '@api/captcha';
import intl from 'react-intl-universal';

interface IState {
    isLoading: boolean;
    svg: any;
    key: string;
    height: number;
    width: number;
    inputValue: any;
}

interface IProps {
    height: number;
    width: number;
    onCall?: Function;
}

export default class ImgCode extends React.Component<IProps, IState> {
    static defaultProps = {
        onCall: null
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: true,
            svg: '',
            key: '',
            height: props.height || 37,
            width: props.width || 140,
            inputValue: undefined
        };
    }

    componentDidMount() {
        this.getCode().catch((err) => console.error(err));
    }

    getCode = async () => {
        const { width, height } = this.state;
        await CaptchaApi.getImg({ width, height })
            .fetch()
            .toPromise()
            .then((res: any) => {
                const { svg, key } = res;
                this.setState({
                    isLoading: false,
                    svg,
                    key
                });
            });
    };

    handleReGet = () => {
        this.setState({ isLoading: true, inputValue: '' });
        this.getCode().catch((err) => console.error(err));
    };

    submitHandle = (e: any) => {
        const targetValue = e.target.value;
        this.setState({ inputValue: targetValue });
        this.props.onCall &&
            this.props.onCall({
                token: {
                    key: this.state.key,
                    imgCode: this.state.inputValue
                },
                validation: 'imgCaptcha'
            });
        this.handleReGet();
    };

    onInput = (e: any) => {
        const targetValue = e.target.value;
        this.setState({ inputValue: targetValue });
    };

    render() {
        const { isLoading, svg, height, inputValue } = this.state;
        return (
            <div className="imgCodewrap">
                <p className="title">
                    <span>{intl.get('Captcha.ImgWarning')}</span>
                    <span className="reload" onClick={this.handleReGet}>
                        {intl.get('Captcha.ImgChange')}
                    </span>
                </p>
                <div className="img">
                    <Spin spinning={isLoading}>
                        <div dangerouslySetInnerHTML={{ __html: isLoading ? '' : svg }} style={{ height: `${height}px` }} />
                    </Spin>
                </div>
                <div className="verify">
                    <Input onInput={this.onInput} value={inputValue} />
                    <Button onClick={this.submitHandle} disabled={isLoading}>
                        {intl.get('Captcha.ConfirmSubmit')}
                    </Button>
                </div>
            </div>
        );
    }
}
