import React, { useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { Carousel } from 'antd';
import classnames from 'classnames';
import styles from './index.module.less';

interface IProps {
    bgImg: string;
    maskBgColor?: string;
    descContent?: any;
    title?: any;
    type?: 'black' | 'black8' | undefined;
    width?: string | number | undefined;
    isAutoHeight?: boolean;
}

const NewBanner: React.FC<IProps> = (props: IProps) => {
    const file_cdn = '/oss-service';
    const { bgImg, maskBgColor, descContent, title, type, width, isAutoHeight } = props;
    // const [actIndex, setActIndex] = useState<number>(0);
    const bannerRef = useRef<any>(null);
    const bannerTextRef = useRef<any>(null);

    return (
        <div className={styles.contianer}>
            <Carousel
                autoplay
                dots={false}
                className={styles['banner-min']}
                ref={bannerRef}
                afterChange={(current) => {
                    bannerTextRef.current && bannerTextRef.current.goTo(current);
                }}
            >
                <div>
                    <div className={styles.banner} style={{ backgroundImage: `url(${file_cdn}${bgImg})` }} />
                </div>
            </Carousel>
            <div className={styles['banner-decorate']}>
                <img loading="lazy" src={`${file_cdn}/static/home/banner-decorate.png`} alt="" />
            </div>
            <div className={styles['banner-mask-con']}>
                <div className={styles['mask-con-w']}>
                    <div className={classnames(styles['mask-con'], isAutoHeight && styles['auto-wid'])} style={width ? { width } : {}}>
                        <div
                            className={classnames(styles.mask, type === 'black' ? styles['mask-bai'] : styles[type || ''])}
                            style={maskBgColor ? { backgroundImage: maskBgColor } : {}}
                        >
                            <div className={styles['text-content']}>
                                <div className={classnames(styles.title, type === 'black' ? styles['tit-black'] : styles[`tit-${type}` ?? ''])}>
                                    {title}
                                </div>
                                <div
                                    className={classnames(styles.desc, type === 'black' ? styles['desc-org'] : styles[`desc-${type}` ?? ''])}
                                    dangerouslySetInnerHTML={{ __html: typeof descContent === 'string' ? descContent : renderToString(descContent) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
NewBanner.defaultProps = {
    maskBgColor: '',
    descContent: '',
    title: '',
    type: undefined,
    width: undefined,
    isAutoHeight: false
};
export default NewBanner;
