import React from 'react';
import intl from 'react-intl-universal';
import style from './index.module.less';

interface Iprops {
    list: Array<{
        title: string;
        desc: string;
    }>;
    textStyles?: React.CSSProperties;
}

const StepsCom: React.FC<Iprops> = (props: Iprops) => {
    const { list, textStyles } = props;

    return (
        <div className={style['step-list']}>
            <div className={style['step-list-con']}>
                <div className={style['step-list']}>
                    {list.map((item, index) => (
                        <div className={style['step-item']} key={item.title || index}>
                            <div className={style['line-con']}>
                                <div className={style.line} />
                                <div className={style['radiu-con']}>
                                    <div className={style['step-num']}>{index >= 10 ? index + 1 : `0${index + 1}`}</div>
                                </div>
                            </div>
                            <div className={style['text-con']} style={textStyles ? { ...textStyles } : {}}>
                                <div
                                    className={style['step-title']}
                                    dangerouslySetInnerHTML={{ __html: item.title ? intl.get(item.title) : item.title }}
                                />
                                <div className={style.desc} dangerouslySetInnerHTML={{ __html: item.desc ? intl.get(item.desc) : item.desc }} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
StepsCom.defaultProps = {
    textStyles: undefined
};
export default StepsCom;
