import React, { useState } from 'react';
import classnames from 'classnames';
import intl from 'react-intl-universal';
import style from './index.module.less';

interface IProps {
    title?: string;
    /** tab项标题 */
    tabTitleList: any[];
    /** tab第一项的内容数据 */
    PrecautionsListOne?: any[];
    /** tab第一项自定义内容 */
    PrecautionsContentOne?: React.ReactNode;
    /** tab第二项的内容数据 */
    PrecautionsListTwo?: { title: string; list: any[] }[];
    /** tab第三项的内容数据 */
    PrecautionsListThree?: any[];
    /** tab第二项内容每一项额外增加的容器类名 */
    twoItemClassName?: string;
    /** tab第二项内容每一项额外增加的标题类名 */
    twoTitleClassName?: string;
    /** tab第三项内容每一项额外增加的标题类名 */
    ThreeItemClassName?: string;
}

const Precautions: React.FC<any> = (props: IProps) => {
    const file_cdn = '/oss-service';
    const {
        title,
        tabTitleList,
        PrecautionsListOne,
        PrecautionsListTwo,
        PrecautionsListThree,
        twoItemClassName,
        PrecautionsContentOne,
        twoTitleClassName,
        ThreeItemClassName
    } = props;
    const {
        options: { currentLocale }
    } = intl as any;
    const [list] = useState(tabTitleList);
    const [actNum, setActNum] = useState<number>(0);

    return (
        <div className={style.container}>
            {Boolean(title) && <div className={style['precautions-tit']}>{intl.get(title) || title}</div>}
            <div className={style['tab-list']}>
                {list.map((item, index) => (
                    <div
                        onClick={() => {
                            setActNum(index);
                        }}
                        className={classnames(style['title-item'], actNum === index ? style.act : null)}
                        key={item.title}
                    >
                        {intl.get(item.title) || item.title}
                    </div>
                ))}
            </div>
            <div className={style.line} />
            <div className={style['card-container']}>
                <div className={style['card-list']}>
                    {actNum === 0 && tabTitleList.length > 0 && (
                        <div>
                            {!PrecautionsContentOne &&
                                PrecautionsListOne.map((item) => (
                                    <div key={item.title} className={style['btn-content']}>
                                        <div className={classnames(style.btn, style[`btn-${currentLocale}`])}>
                                            {intl.get(item.title) || item.title}
                                        </div>
                                        <div className={style['btn-desc']} dangerouslySetInnerHTML={{ __html: intl.get(item.desc) || item.desc }} />
                                    </div>
                                ))}
                            {PrecautionsContentOne}
                        </div>
                    )}
                    {actNum === 1 && tabTitleList.length > 1 && (
                        <div className={style['content-flex']}>
                            {PrecautionsListTwo.map((item) => (
                                <div
                                    key={`${tabTitleList.length}_${item.title}`}
                                    className={classnames(style['list-content-item'], twoItemClassName)}
                                >
                                    {Boolean(item.title) && (
                                        <div className={style['btn-content']}>
                                            <div className={classnames(style['three-btn'], twoTitleClassName)}>
                                                {intl.get(item.title) || item.title}
                                            </div>
                                        </div>
                                    )}
                                    <div className={style['tab-two-con']}>
                                        <div>
                                            <div className={style['tab-two-list']}>
                                                {item.list.map((i) => (
                                                    <div key={i.title} className={style['two-item-tit']}>
                                                        {intl.get(i.title) || i.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {actNum === 2 && tabTitleList.length > 2 && (
                        <div className={style['three-two-con']}>
                            <div className={classnames(style['three-two-list'], ThreeItemClassName)}>
                                {PrecautionsListThree.map((item) => (
                                    <div key={item.title} className={style['btn-content']}>
                                        <div className={style['three-btn']}>{intl.get(item.title) || item.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <img loading="lazy" className={style['super-hui']} src={`${file_cdn}/static/hong-kong-register/super-hui.svg`} alt="" />
        </div>
    );
};

Precautions.defaultProps = {
    title: '',
    PrecautionsListOne: [],
    PrecautionsListTwo: [],
    PrecautionsListThree: [],
    twoItemClassName: '',
    PrecautionsContentOne: undefined,
    twoTitleClassName: '',
    ThreeItemClassName: ''
};

export default Precautions;
