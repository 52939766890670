import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import intl from 'react-intl-universal';
import { useRouter } from 'next/router';
import style from './style.module.less';

const MenuChildLlist = [
    {
        name: 'Menu.CompanySecretary',
        id: '01'
    },
    {
        name: 'Menu.ImmigrantVisa',
        id: '02'
    },
    {
        name: 'Menu.FinancialManagement',
        id: '03'
    }
];
const MenuContentFirst = [
    {
        name: 'Menu.HKCompanyRegistration',
        url: '/services/hongkong'
    },
    {
        name: 'Menu.SingaporeCompanyRegistration',
        url: '/services/singapore'
    },
    {
        name: 'Menu.CaymanCompanyRegistration',
        url: '/services/cayman'
    },
    {
        name: 'Menu.BVICompanyRegistration',
        url: '/services/bvi'
    },
    {
        name: 'Menu.SeychellesCompanyRegistration',
        url: '/services/seychelles'
    },
    {
        name: 'Menu.SaintVincentCompanyRegistration',
        url: '/services/saintvincent'
    },
    {
        name: 'Menu.DubaiCompanyRegistration',
        url: '/services/dubai'
    }
];
const MenuContentTwoList = [
    {
        name: 'Menu.USCompanyRegistration',
        url: '/services/usa'
    },
    {
        name: 'Menu.VanuatuCompanyRegistration',
        url: '/services/vanuatu'
    },
    {
        name: 'Menu.MauritiusCompanyRegistration',
        url: '/services/mauritius'
    },
    {
        name: 'Menu.KoreanCompanyRegistration',
        url: '/services/korea'
    },
    {
        name: 'Menu.JapaneseCompanyRegistration',
        url: '/services/japan'
    },
    {
        name: 'Menu.MalaysiaCompanyRegistration',
        url: '/services/malaysia'
    }
];

const menuServicesListOne = [
    {
        name: 'Menu.HKTalentsProgram',
        url: '/services/hongkongym'
    },
    {
        name: 'Menu.HKMainlandTalentsProgram',
        url: '/services/hongkongzc'
    },
    {
        name: 'Menu.HKTopTalentsProgram',
        url: '/services/hongkonggc'
    },
    // {
    //     name: 'Menu.PortugalHousePurchaseImmigration',
    //     url: '/services/portugalym'
    // },
    {
        name: 'Menu.HongKongCapitalInvestorEntryScheme',
        url: '/services/cies'
    },
    {
        name: 'Menu.TurkeyInvestmentImmigration',
        url: '/services/turkeyym'
    },
    {
        name: 'Menu.DubaiGoldenVisa',
        url: '/services/dubaihjqz'
    }
    // {
    //     name: 'Menu.VanuatuPassport',
    //     url: '/services/vanuatuym'
    // }
];

const menuServicesListTwo = [
    {
        name: 'Menu.DubaiWorkTouristVisa',
        url: '/services/dubaiqz'
    },
    {
        name: 'Menu.SingaporeWorkPermit',
        url: '/services/singaporeqz'
    },
    // {
    //     name: 'Menu.ThailandEliteVisa',
    //     url: '/services/thailandqz'
    // },
    {
        name: 'Menu.MauritiusSuperVisa',
        url: '/services/mauritiusqz'
    }
];

const menuContentRList = [
    {
        name: 'Menu.OverseasBankAccountOpening',
        url: '/services/bank'
    },
    {
        name: 'Menu.AnnualAuditServices',
        url: '/services/audit'
    },
    {
        name: 'Menu.InternationalCertificationNotarization',
        url: '/services/notarization'
    },
    {
        name: 'Menu.ChinaCommissionedNotarization',
        url: '/services/chinanotary'
    }
];

const menuFinancialList = [
    {
        name: 'Menu.AccountingFinancialReporting',
        url: '/services/accounting'
    },
    {
        name: 'Menu.TaxAdvisoryCompliance',
        url: '/services/tax'
    }
];
interface Iprops {
    onClose: () => void;
}
const MenuChild = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { onClose } = props;
    const [menuChildLlist] = useState(MenuChildLlist);
    const [menuContentFirsts] = useState(MenuContentFirst);
    const [menuContentTwoLists] = useState(MenuContentTwoList);
    const [actNumber, setActNumber] = useState<number>(0);
    const router = useRouter();
    const navAct = (val: string) => {
        const path = router.pathname;
        return path === val;
    };
    const titleRender = (name: string, url?: string) => (
        <div
            className={classnames(style['menu-child-title'], navAct(url || '') ? style.act : '')}
            onClick={() => {
                if (url === router.pathname) {
                    onClose && onClose();
                }
            }}
        >
            <div>{name}</div>
            <img loading="lazy" src={`${file_cdn}/static/icon/red-arr.svg`} alt="" />
        </div>
    );
    useEffect(() => {
        const path = router.pathname;
        const oneList = [...menuContentFirsts, ...menuContentTwoLists];
        const TwoList = [...menuServicesListOne, ...menuServicesListTwo];
        const oneFlag = oneList.some((item) => item.url === path);
        const twoFlag = TwoList.some((item) => item.url === path);
        const threeFlag = menuFinancialList.some((item) => item.url === path);
        if (oneFlag) {
            setActNumber(0);
        }
        if (twoFlag) {
            setActNumber(1);
        }
        if (threeFlag) {
            setActNumber(2);
        }
    }, [router, menuContentFirsts, menuContentTwoLists]);
    return (
        <div className={style['menu-child-container']}>
            <img
                loading="lazy"
                className={style['nav-close-icon']}
                onClick={() => {
                    onClose && onClose();
                }}
                src={`${file_cdn}/static/icon/icon_导航_关闭.svg`}
                alt=""
            />
            <div className={style['menu-child-con']}>
                <div className={style['menu-child-content']}>
                    <div className={style['menu-child-flex']}>
                        <div className={style['menu-child-l']}>
                            {menuChildLlist.map((item, index) => (
                                <div
                                    className={classnames(style['menu-child-item'], actNumber === index ? style.act : null)}
                                    key={item.id}
                                    onClick={() => {
                                        setActNumber(index);
                                    }}
                                >
                                    {intl.get(item.name) || item.name}
                                </div>
                            ))}
                        </div>
                        {actNumber === 0 && (
                            <div className={style['menu-child-r']}>
                                <div className={style['menu-right-l-con']}>
                                    <Link href="/services/offshore">
                                        {titleRender(intl.get('Menu.OverseasCompanyRegistration'), '/services/offshore')}
                                    </Link>
                                    <div className={style['menu-right-l--flex']}>
                                        <div className={style['menu-first']}>
                                            {menuContentFirsts.map((item) => (
                                                <Link href={item?.url} key={item.name}>
                                                    <div
                                                        onClick={() => {
                                                            if (item.url === router.pathname) {
                                                                onClose && onClose();
                                                            }
                                                        }}
                                                        className={classnames(style['menu-link-tit'], navAct(item?.url) ? style.act : '')}
                                                    >
                                                        {intl.get(item.name)}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                        <div className={style['menu-two']}>
                                            {menuContentTwoLists.map((item) => (
                                                <Link href={item?.url} key={item.name}>
                                                    <div
                                                        onClick={() => {
                                                            if (item.url === router.pathname) {
                                                                onClose && onClose();
                                                            }
                                                        }}
                                                        className={classnames(style['menu-link-tit'], navAct(item?.url) ? style.act : '')}
                                                    >
                                                        {intl.get(item.name)}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className={style['menu-right-r-con']}>
                                    {menuContentRList.map((item) => (
                                        <div className={style['menu-bold-tit']} key={item.name}>
                                            <Link href={item?.url} key={item.url}>
                                                {titleRender(intl.get(item.name), item.url)}
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                        {actNumber === 1 && (
                            <div className={style['menu-child-r']}>
                                <div className={style['menu-right-l-con']}>
                                    {titleRender(intl.get('Menu.ImmigrationServices'))}
                                    <div className={style['menu-right-l--flex']}>
                                        <div className={style['menu-first']}>
                                            {menuServicesListOne.map((item) => (
                                                <Link href={item?.url} key={item.name}>
                                                    <div
                                                        onClick={() => {
                                                            if (item.url === router.pathname) {
                                                                onClose && onClose();
                                                            }
                                                        }}
                                                        className={classnames(style['menu-link-tit'], navAct(item?.url) ? style.act : '')}
                                                    >
                                                        {intl.get(item.name) || item.name}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className={style['menu-right-r-con']}>
                                    {titleRender(intl.get('Menu.VisaService'))}
                                    <div className={style['menu-right-l--flex']}>
                                        <div className={style['menu-first']}>
                                            {menuServicesListTwo.map((item) => (
                                                <Link href={item?.url} key={item.name}>
                                                    <div
                                                        onClick={() => {
                                                            if (item.url === router.pathname) {
                                                                onClose && onClose();
                                                            }
                                                        }}
                                                        className={classnames(style['menu-link-tit'], navAct(item?.url) ? style.act : '')}
                                                    >
                                                        {intl.get(item.name)}
                                                    </div>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {actNumber === 2 && (
                            <div className={style['menu-child-r']}>
                                <div className={classnames(style['menu-right-r-con'], style['menu-right-r-con-mar'])}>
                                    {menuFinancialList.map((item) => (
                                        <div className={classnames(style['menu-link-tit'], navAct(item?.url) ? style.act : '')} key={item.name}>
                                            <Link href={item?.url}>{titleRender(intl.get(item.name), item.url)}</Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MenuChild;
