import React from 'react';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import style from './index.module.less';

interface IProps {
    subChildren: Array<any>;
    // locale: string;
    parentIndex: number;
    indexChange: (parentIndex: number, childIndex: number) => void;
    childIndexChange: (parentIndex: number, childIndex: number, childIndex2: number) => void;
    child2IndexChange: (parentIndex: number, childIndex: number, childIndex2: number, childIndex3: number) => void;
    onClose: () => void;
}

const MMenuChild = (props: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;
    const { subChildren, indexChange, parentIndex, childIndexChange, onClose, child2IndexChange } = props;
    const router = useRouter();
    // const clickTime = useRef<number>();
    const indexHandle = (index: number) => {
        indexChange(parentIndex, index);
    };
    const childIndexHandle = (index: number, index2: number) => {
        childIndexChange(parentIndex, index, index2);
    };
    return (
        <div className={style['menu-child-con']}>
            <div className={style.blank} />
            {subChildren.map((item: any, index: number) => (
                <div className={style['children-item']} key={item.id}>
                    <div title={intl.get(item.name)}>
                        <div className={style['']}>
                            <div className={classnames(style['menu-slide'], item.isOpen && item.children ? style.act : null)}>
                                <a
                                    className={style['nav-text']}
                                    onClick={() => {
                                        indexHandle(index);
                                        if (item.link) {
                                            router.push(item.link);
                                        }
                                        if (item.link === router.pathname) {
                                            onClose && onClose();
                                        }
                                    }}
                                >
                                    {intl.get(item.name) || item.name}
                                </a>
                                <div
                                    onClick={() => {
                                        indexHandle(index);
                                    }}
                                >
                                    {item.isOpen && item.children ? (
                                        <img
                                            loading="lazy"
                                            className={style['chil-nav-arr']}
                                            src="/oss-service/static/menu/icon-nav-arr-act.svg"
                                            alt=""
                                        />
                                    ) : (
                                        <img
                                            loading="lazy"
                                            className={classnames(style['chil-nav-arr'], style.default)}
                                            src="/oss-service/static/menu/icon-nav-arr.svg"
                                            alt=""
                                        />
                                    )}
                                </div>
                            </div>
                            <div className={style['nav-line']} />
                        </div>
                        {item.children &&
                            item.isOpen &&
                            item.children.map((item2, index2) => (
                                <div className={style['nav-bottom-chil']} key={item2.id}>
                                    <div className={classnames(style['menu-slide2'], item2.isOpen && item2.children ? style.act : null)}>
                                        <a
                                            className={classnames(style['nav-children-text'], item2.isOpen ? style.act : '')}
                                            onClick={() => {
                                                childIndexHandle(index, index2);
                                                if (item2.link) {
                                                    router.push(item2.link);
                                                }
                                                if (item2.link === router.pathname) {
                                                    onClose && onClose();
                                                }
                                            }}
                                        >
                                            {intl.get(item2.name) || item2.name}
                                        </a>
                                        {item2.children && (
                                            <div
                                                onClick={() => {
                                                    childIndexHandle(index, index2);
                                                }}
                                            >
                                                <>
                                                    {item2.isOpen && item2.children ? (
                                                        <img
                                                            loading="lazy"
                                                            className={style['chil-nav-arr']}
                                                            src="/oss-service/static/menu/icon-nav-arr-act.svg"
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <img
                                                            loading="lazy"
                                                            className={classnames(style['chil-nav-arr'], style.default)}
                                                            src="/oss-service/static/menu/icon-nav-arr.svg"
                                                            alt=""
                                                        />
                                                    )}
                                                </>
                                            </div>
                                        )}
                                    </div>
                                    <div className={style['nav-line']} />
                                    {item2.children &&
                                        item2.isOpen &&
                                        item2.children.map((item3, index3) => (
                                            <div className={classnames(style['menu-slide3'])} key={item3.id}>
                                                <a
                                                    className={classnames(
                                                        style['nav-children-text'],
                                                        style[`nav-children-${currentLocale}`],
                                                        item3.isOpen ? style.act : ''
                                                    )}
                                                    onClick={() => {
                                                        child2IndexChange(parentIndex, index, index2, index3);
                                                        if (item3.link) {
                                                            router.push(item3.link);
                                                        }
                                                        if (item3.link === router.pathname) {
                                                            onClose && onClose();
                                                        }
                                                    }}
                                                >
                                                    {intl.get(item3.name) || item3.name}
                                                </a>
                                            </div>
                                        ))}
                                </div>
                            ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MMenuChild;
