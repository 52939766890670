import { CSSProperties } from 'react';

interface IProps {
    type: string;
    className?: string;
    style?: CSSProperties;
    onClick?: () => {} | void;
    title?: string;
}

const IconFont: React.FC<IProps> = ({ type, className, onClick, title, style }: IProps) => (
    <span className={['iconfont', `icon-${type}`, className].join(' ')} onClick={onClick} title={title} style={style} />
);

IconFont.defaultProps = {
    className: '',
    style: {},
    onClick: null,
    title: ''
};

export default IconFont;
