import * as React from 'react';
import intl from 'react-intl-universal';
import Container from '../Container';

declare global {
    interface Window {
        capInit: Function;
        capDestroy: Function;
        capRefresh: Function;
        grecaptcha: any;
        TencentCaptcha: any;
        captchaAppId: string;
    }
}

interface IProps {
    onCall?: ({ token, validation, type }: { token?: string; validation?: 'gCaptcha' | 'tCaptcha'; type?: string }) => any;
    onReload?: any;
    lang: string;
    unique?: boolean;
    type?: string;
}

interface IState {
    status: 'success' | 'init' | 'failed' | 'unInit';
    useChrome: boolean;
    useTencent: boolean;
    type: string;
}

export default class Captcha extends React.Component<IProps, IState> {
    static defaultProps = {
        onCall: null,
        onReload: null,
        unique: false,
        type: ''
    };

    static tLang: { [key: string]: number } = {
        zh_CNS: 2052,
        zh_CNT: 1028,
        en_US: 1033
    };

    static gLang: { [key: string]: string } = {
        zh_CNS: 'zh-CN',
        zh_CNT: 'zh-TW',
        en_US: 'en'
    };

    private widget_id: any;

    private locales: any = {
        init: intl.get('Captcha.ClickToCheck'),
        success: intl.get('Captcha.CheckSuccess'),
        failed: intl.get('Captcha.CheckFailed')
    };

    constructor(props: IProps) {
        super(props);
        this.state = {
            status: 'unInit',
            useChrome: !!window.grecaptcha && !window.TencentCaptcha, // 谷歌验证
            useTencent: !!window.TencentCaptcha && !window.grecaptcha, // 腾讯验证
            type: props.type
        };
    }

    // UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    //     const { onReload } = nextProps;
    //     const { onReload: reloaded } = this.props;
    //     onReload && reloaded !== onReload && this.reload();
    // }

    componentDidUpdate(prevProps) {
        if (this.props.onReload !== prevProps.onReload) {
            prevProps.onReload && this.reload();
        }
    }

    componentWillUnmount() {
        // const { useTencent } = this.state;
        // useTencent && window.capDestroy();
        Container.destroy();
    }

    /**
     * 重新初始化
     */
    reload = () => {
        const useChrome = !!window.grecaptcha && !window.TencentCaptcha;
        const useTencent = !!window.TencentCaptcha && !window.grecaptcha;

        const { unique } = this.props;
        if (useChrome && this.state.status === 'success') {
            window.grecaptcha.reset(this.widget_id);
            this.setState({ status: 'init' });
        } else {
            useChrome && this.getChrome();
        }
        if (useTencent) {
            Container.show({
                className: 'tCaptchaMask',
                Content: null
            });
        }
        if (useTencent && !unique && (this.state.status === 'success' || this.state.status === 'init')) {
            // window.capRefresh();
        } else {
            useTencent && this.getTencent();
        }
        this.setState(
            {
                status: 'init',
                useChrome,
                useTencent
            },
            () => {
                this.errorHandler();
            }
        );
    };

    /**
     * 获取腾讯验证
     */
    getTencent = () => {
        // window.capInit(document.getElementById('tCaptcha'), {
        //     lang: Captcha.tLang[this.props.lang],
        //     callback: this.callbackHandler(({ ticket }: { ticket: string }) => ticket),
        //     type: 'popup',
        //     pos: 'fixed'
        // });
        const key = 'tCaptcha';
        const dom = document.getElementById(key);
        try {
            const tencentCaptcha = new window.TencentCaptcha(
                dom,
                window.captchaAppId,
                async (res: any) => {
                    if (res.ret === 0) {
                        await this.callbackHandler()({
                            ticket: res.ticket,
                            randstr: res.randstr
                        });
                    } else {
                        this.setState({ status: 'init' });
                    }
                },
                {
                    needFeedBack: false
                }
            );
            console.log(tencentCaptcha, 'tencentCaptcha');
            dom.click();
            this.setState({ status: 'init' });
        } catch (error) {
            console.log({ error });
            this.setState({ status: 'init' });
        }
    };

    /**
     * 获取谷歌验证
     */
    getChrome = () => {
        const { type } = this.state;
        this.widget_id = window.grecaptcha.render(`gCaptcha-${type}`, {
            sitekey: '6LdG-2IUAAAAAHxbgKtBH_QONGlu4TuIUtzarjTx',
            callback: this.callbackHandler(),
            hl: Captcha.gLang[this.props.lang]
        });
    };

    /**
     * 回调控制
     */
    callbackHandler = (format?: Function) => async (res: any) => {
        const response = typeof format === 'function' ? format(res) : res;
        if (this.state.useTencent) Container.close();
        if (response) {
            try {
                this.successHandler(response);
            } catch (err) {
                // 票据验证失败
                this.errorHandler();
            }
        } else {
            // 票据验证失败
            this.props.onCall && this.props.onCall({ type: 'reBuild' });
            this.setState({ status: 'init' });
        }
    };

    /**
     * 成功处理
     */
    successHandler = (res: any) => {
        const { useChrome } = this.state;
        const { onCall, unique } = this.props;
        onCall && onCall({ token: res, validation: useChrome ? 'gCaptcha' : 'tCaptcha' });
        if (unique) {
            // const { useTencent } = this.state;
            // useTencent && window.capDestroy();
        }
        this.setState({ status: 'success' });
    };

    /**
     * 错误处理
     */
    errorHandler = () => {
        const { useChrome, useTencent } = this.state;
        const { onCall } = this.props;
        if (useChrome) {
            this.widget_id && window.grecaptcha.reset(this.widget_id);
            this.setState({ status: 'init' });
        }
        if (useTencent) {
            // window.capRefresh();
            onCall && onCall({ type: 'reBuild' });
            this.setState({ status: 'failed' });
        }
    };

    render() {
        const { useChrome, useTencent, status, type } = this.state;
        const noService = !useChrome && !useTencent;
        return (
            <div className="captcha">
                <div id="tCaptcha" />
                <div className="gCaptchaWrapper" style={{ display: ['unInit', 'success'].includes(status) || useTencent ? 'none' : 'block' }}>
                    <div id={`gCaptcha-${type}`} className="gCaptcha" />
                    <div className="gCaptchaFloat">{this.locales[status]}</div>
                    {noService && status !== 'unInit' ? (
                        <div className="gCaptchaError" onClick={this.reload}>
                            {intl.get('Captcha.InitError')}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }
}
