import React from 'react';
import ReactDom from 'react-dom';

interface IState {
    Content: React.ComponentClass;
    config?: any;
    className?: string;
    callback?: any;
}

class Container extends React.Component<any, IState> {
    state: IState = {
        Content: null,
        config: {},
        className: '',
        callback: {}
    };

    /**
     * 显示
     */
    show = (state: IState) => this.setState(state);

    /**
     * 隐藏
     */
    close = () => {
        this.setState({ className: 'container-hide' });
    };

    /**
     * 销毁
     */
    destroy = () =>
        this.setState({
            Content: null,
            config: {},
            className: ''
        });

    render() {
        const { className, Content, config, callback } = this.state;
        return (
            <div className={className}>
                {Content ? <Content {...config} callback={callback} onClose={this.close} onDestroy={this.destroy} /> : null}
            </div>
        );
    }
}

/**
 * 动态创建dom容器
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
function getModalContainer(ModalContainer: React.ComponentClass) {
    if (process.browser) {
        const div = document.createElement('div');
        document.body.appendChild(div);
        return ReactDom.render(<ModalContainer />, div) as any;
    }
}

/**
 * 暴露类实例
 */
export default getModalContainer(Container);
