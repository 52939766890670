import React from 'react';
import intl from 'react-intl-universal';
import Icon from '@components/Common/IconFont';
import CardShowType from './cardShowType';
import style from './style.module.less';

interface IProps {
    pages?: string;
    isMobile?: boolean;
}

const CardShow: React.FC<IProps> = ({ pages, isMobile }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={isMobile ? [style.cardContainer, style.MobilecardCon].join(' ') : style.cardContainer}>
            <div className={style.cardShow}>
                <h1>{intl.get(`${pages}.Advantage`)}</h1>
                <div className={style.showAdvantage}>
                    {CardShowType[pages].map((item: any) => (
                        <div className={style.singleCard} key={item.key}>
                            <div>
                                {item.icon ? (
                                    <Icon type={item.icon} className={style.cardIcon} />
                                ) : (
                                    <img loading="lazy" src={`${file_cdn}/static/icon/${item.img}.png`} alt="" />
                                )}
                            </div>
                            <p>{intl.get(`${pages}.${item.content}`)}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

CardShow.defaultProps = {
    pages: 'OffShore',
    isMobile: false
};

export default CardShow;
