import http from '@utils/http';

class CaptchaApi {
    private readonly prefix = 'captcha';

    /**
     * 图片验证码
     */
    getImg = (data: any) => http.get(`${this.prefix}/getImg`, data);

    /**
     * 腾讯或谷歌验证
     */
    getCaptcha = () => http.get(`${this.prefix}/getCaptcha`);
}

export default new CaptchaApi();
