import { useState, useEffect, ReactNode } from 'react';
import intl from 'react-intl-universal';
import { Popover } from 'antd';
import Script from '@utils/script';
import CaptchaApi from '@api/captcha';
import Captcha from '../Captcha';
import ImgCode from '../Captcha/imgCode';

export type tokenType = string | { key: string; imgCode: string };
export type validationType = 'gCaptcha' | 'tCaptcha' | 'imgCaptcha';

interface CallProps {
    token?: tokenType;
    validation?: validationType;
    type?: string;
}

interface IProps {
    onSend: Function;
    disabled: boolean;
    successHandle: Function;
    children?: ReactNode;
    type?: string;
}

const Verify = (props: IProps) => {
    const [isReload, setIsReload] = useState(false);
    const [visible, setVisible] = useState(false);
    const {
        options: { currentLocale }
    } = intl as any;
    const [codeType, setCodeType] = useState('captcha');
    const [mount, setMount] = useState(false);

    useEffect(() => {
        setMount(true);
        if (mount && !(window.grecaptcha || window.capInit)) {
            // 默认加载腾讯或谷歌验证，失败降级使用图片验证。
            const cbFN = (src) => {
                const captchaScript = document.querySelector('script[key=captchaJS]');
                if (!captchaScript) {
                    Script.load(src, 1000, [{ key: 'key', value: 'captchaJS' }]).catch(() => setCodeType('imgCode'));
                }
                if (!src) {
                    setCodeType('imgCode');
                }
            };
            CaptchaApi.getCaptcha()
                .fetch()
                .toPromise()
                .then(({ src }: { src: string }) => {
                    try {
                        const data = JSON.parse(src);
                        cbFN(data.captchaJS);
                        window.captchaAppId = data.captchaAppId;
                    } catch (error) {
                        cbFN(src);
                    }
                });
        }
    }, [mount]);

    /**
     * 发送验证码
     */
    const SendHandle = () => {
        if (props.disabled) return;
        setVisible(true);
        setIsReload(true);
    };

    /**
     * 发送验证码回调
     */
    const onCall = async ({ token, validation, type }: CallProps) => {
        const { onSend } = props;
        setVisible(false);
        if (type !== 'reBuild') {
            onSend(token, validation)
                .fetch()
                .subscribe(
                    () => {
                        setIsReload(false);
                        props.successHandle();
                    },
                    () => {
                        setIsReload(false);
                        setVisible(true);
                    }
                );
        } else {
            setIsReload(false);
        }
    };

    /**
     * 图片验证码显隐处理
     */
    const handleVisibleChange = (show: boolean) => {
        if (!show) {
            setVisible(show);
        }
    };

    return (
        <>
            {codeType === 'captcha' ? (
                <>
                    <div className="captchaBtn" onClick={SendHandle}>
                        {props.children}
                    </div>
                    <Captcha onReload={isReload} onCall={onCall} lang={currentLocale} unique type={props.type} />
                </>
            ) : (
                <Popover
                    trigger="click"
                    visible={visible}
                    onVisibleChange={handleVisibleChange}
                    content={
                        <div style={{ width: '224px' }}>
                            <ImgCode height={40} width={224} onCall={onCall} />
                        </div>
                    }
                >
                    <div className="captchaBtn" onClick={SendHandle}>
                        {props.children}
                    </div>
                </Popover>
            )}
        </>
    );
};

Verify.defaultProps = {
    children: null,
    type: 'default'
};

export default Verify;
