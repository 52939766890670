class Cookie {
    get = (cname: string) => {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i].trim();
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }
        return '';
    };

    set = (name: string, value: string) => {
        const expires = '; expires=Fri, 31 Dec 9999 23:59:59 GMT';
        const path = '; path=/';
        document.cookie = `${name}=${value}${expires}${path}`;
    };
}

export default new Cookie();
