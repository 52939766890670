import React from 'react';
import intl from 'react-intl-universal';
import style from './index.module.less';

interface Iprops {
    dataList: {
        id: string;
        title: string;
        desc: string;
        img: string;
    }[];
}

const CommercialNotarization: React.FC<Iprops> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { dataList } = props;
    return (
        <div className={style.container}>
            <div className={style['card-list']}>
                {dataList.map((item) => (
                    <div key={item.id} className={style['card-item']} style={{ backgroundImage: `url(${file_cdn}${item.img})` }}>
                        <div className={style.mask}>
                            <div className={style['mask-tit']}>{intl.get(item.title)}</div>
                            <div className={style['mask-desc']}>{intl.get(item.desc)}</div>
                        </div>
                        <div className={style['title-mask']} />
                        <div className={style['card-title']}>
                            <p>{intl.get(item.title)}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CommercialNotarization;
