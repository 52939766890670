class Script {
    /**
     * 脚本加载
     * @param url 脚本地址
     * @param timeout 超时时长
     * @param attr 附加属性
     */
    load = function (url: string, timeout: number, attr?: Array<{ key: string; value: string }>) {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            for (const { key, value } of attr) {
                script.setAttribute(key, value);
            }
            script.onload = function () {
                resolve(null);
            };
            script.src = url;
            document.body.append(script);
            setTimeout(function () {
                reject();
            }, timeout);
        });
    };
}

export default new Script();
