import React from 'react';
import { Row, Col } from 'antd';
import intl from 'react-intl-universal';
import style from './style.module.less';

interface IProps {
    pages?: String;
    component?: String;
    isMobile?: boolean;
}
const Banner: React.FC<IProps> = ({ pages, component, isMobile }: IProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    return (
        <div lang={currentLocale} className={isMobile ? [style.mobileContainer, style.bannerContainer].join(' ') : style.bannerContainer}>
            <img className={style.bannerImg} src={`${file_cdn}/static/${pages}/banner.jpg`} alt="banner" />
            <div className={isMobile ? [style.mobileBanner, style.banner].join(' ') : style.banner}>
                <div className={isMobile ? [style.mobileInfo, style.bannerInfo].join(' ') : style.bannerInfo}>
                    {isMobile ? (
                        <div>
                            <h2>{intl.get(`${component}.SloganOne`) || intl.get(`${component}.Slogan`)}</h2>
                            <h2>{intl.get(`${component}.SloganTwo`)}</h2>
                        </div>
                    ) : (
                        <Row>
                            <Col span={15}>
                                <h2>{intl.get(`${component}.Slogan`)}</h2>
                            </Col>
                            <Col span={7} />
                        </Row>
                    )}
                    {currentLocale === 'en_US' ? (
                        <p dangerouslySetInnerHTML={{ __html: intl.get(`${component}.SloganIntroduce`) }} />
                    ) : (
                        <p>{intl.get(`${component}.SloganIntroduce`)}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

Banner.defaultProps = {
    pages: 'home',
    component: 'HomePage',
    isMobile: false
};

export default Banner;
