import React from 'react';
import intl from 'react-intl-universal';
import style from '../style.module.less';

const StepOne: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('Bank.AddressAccount')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Bank.SuggestChoose')}</span>
        </p>
    </>
);

const StepTwo: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('Bank.NeedProvide')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Bank.RegistrationFile')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Bank.Beneficiary')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Bank.RegistrationCompony')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Bank.RegistrationCertificate')}</span>
        </p>
    </>
);

const StepThree: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('Bank.SupplementFile')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Bank.SuccessApplication')}</span>
        </p>
    </>
);

export default { StepOne, StepTwo, StepThree };
