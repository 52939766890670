import IconFont from './IconFont';
import Layout from './Layout';
import Banner from './Banner';
import NewBanner from './NewBanner';
import Explanation from './Explanation';
import CardShow from './CardShow';
import Process from './Process';
import Verify from './Verify';
import Titlecom from './Titlecom';
import ChooseFinovy from './ChooseFinovy';
import StepsCom from './StepsCom';
import CommercialNotarization from './CommercialNotarization';
import Precautions from './Precautions';

export {
    IconFont,
    Layout,
    Banner,
    Explanation,
    CardShow,
    Process,
    Verify,
    NewBanner,
    Titlecom,
    ChooseFinovy,
    StepsCom,
    CommercialNotarization,
    Precautions
};
