import React from 'react';
import Link from 'next/link';
import intl from 'react-intl-universal';
import classnames from 'classnames';
import style from './index.module.less';

interface IProps {
    subChildren: Array<any>;
    // locale: string;
    parentIndex: number;
    indexChange: (parentIndex: number, childIndex: number) => void;
}

const MMenuChild = (props: IProps) => {
    const { subChildren, indexChange, parentIndex } = props;
    const indexHandle = (index: number) => {
        indexChange(parentIndex, index);
    };
    return (
        <div>
            <div className={style.blank} />
            {subChildren.map((item: any, index: number) => (
                <div className={style['children-item']} key={item.id}>
                    <div className={style['']}>
                        <div className={classnames(style['menu-slide'], item.isOpen && item.children ? style.act : null)}>
                            {item.link ? (
                                <Link href={item.link} key={item.link} passHref>
                                    <div className={style['nav-text']}>{intl.get(item.name) || item.name}</div>
                                </Link>
                            ) : (
                                <div className={style['nav-text']}>{intl.get(item.name) || item.name}</div>
                            )}
                            <div
                                onClick={() => {
                                    indexHandle(index);
                                }}
                            >
                                {item.children && (
                                    <img
                                        loading="lazy"
                                        className={classnames(style['icon-nav-arr'], item.isOpen ? style.act : null)}
                                        src="/static/footer/xiaojiantou.svg"
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                        <div className={style['nav-line']} />
                    </div>
                    {item.children &&
                        item.isOpen &&
                        item.children.map((item2) => (
                            <div className={style['nav-bottom-child']} key={item2.id}>
                                <div>
                                    <Link href={item2.link} key={item2.link} passHref>
                                        <div className={style['nav-children-text']}>{intl.get(item2.name) || item2.name}</div>
                                    </Link>
                                </div>
                                <div className={style['nav-line']} />
                            </div>
                        ))}
                </div>
            ))}
        </div>
    );
};

export default MMenuChild;
