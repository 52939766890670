import React, { useState } from 'react';
import intl from 'react-intl-universal';
import Link from 'next/link';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';
import Icon from '../../Common/IconFont';
import style from './index.module.less';
import MMenuChild from './menuChild';

const FooterNavigateMap = [
    // {
    //     name: 'Menu.Home',
    //     link: '/'
    // },
    {
        name: 'Menu.CompanySecretary',
        link: '',
        id: '01',
        isOpen: false,
        children: [
            {
                name: 'Menu.OverseasRegistration',
                id: '02',
                link: '/services/offshore',
                isOpen: false,
                children: [
                    {
                        id: '03',
                        name: 'Menu.HKCompanyRegistration',
                        link: '/services/hongkong'
                    },
                    {
                        id: '04',
                        name: 'Menu.SingaporeCompanyRegistration',
                        link: '/services/singapore'
                    },
                    {
                        id: '05',
                        name: 'Menu.CaymanCompanyRegistration',
                        link: '/services/cayman'
                    },
                    {
                        id: '06',
                        name: 'Menu.BVICompanyRegistration',
                        link: '/services/bvi'
                    },
                    {
                        id: '07',
                        name: 'Menu.SeychellesCompanyRegistration',
                        link: '/services/seychelles'
                    },
                    {
                        id: '08',
                        name: 'Menu.SaintVincentCompanyRegistration',
                        link: '/services/saintvincent'
                    },
                    {
                        id: '09',
                        name: 'Menu.DubaiCompanyRegistration',
                        link: '/services/dubai'
                    },
                    {
                        id: '10',
                        name: 'Menu.USCompanyRegistration',
                        link: '/services/usa'
                    },
                    {
                        id: '11',
                        name: 'Menu.VanuatuCompanyRegistration',
                        link: '/services/vanuatu'
                    },
                    {
                        id: '12',
                        name: 'Menu.MauritiusCompanyRegistration',
                        link: '/services/mauritius'
                    },
                    {
                        id: '13',
                        name: 'Menu.KoreanCompanyRegistration',
                        link: '/services/korea'
                    },
                    {
                        id: '14',
                        name: 'Menu.JapaneseCompanyRegistration',
                        link: '/services/japan'
                    },
                    {
                        id: '15',
                        name: 'Menu.MalaysiaCompanyRegistration',
                        link: '/services/malaysia'
                    }
                ]
            },
            {
                id: '16',
                name: 'Menu.AuditService',
                link: '/services/audit'
            },
            {
                id: '17',
                name: 'Menu.InternationalCertificationNotarization',
                link: '/services/notarization'
            },
            {
                id: '18',
                name: 'Menu.OverseasBankAccount',
                link: '/services/bank'
            }
        ]
    },
    {
        name: 'Menu.ImmigrantVisa',
        link: '',
        id: '19',
        isOpen: false,
        children: [
            {
                name: 'Menu.ImmigrationServices',
                link: '',
                isOpen: false,
                id: '20',
                children: [
                    {
                        id: '21',
                        name: 'Menu.HKTalentsProgram',
                        link: '/services/hongkongym'
                    },
                    {
                        id: '22',
                        name: 'Menu.HKMainlandTalentsProgram',
                        link: '/services/hongkongzc'
                    },
                    {
                        id: '23',
                        name: 'Menu.HKTopTalentsProgram',
                        link: '/services/hongkonggc'
                    },
                    // {
                    //     id: '21',
                    //     name: 'Menu.PortugalHousePurchaseImmigration',
                    //     link: '/services/portugalym'
                    // },
                    {
                        id: '25',
                        name: 'Menu.HongKongCapitalInvestorEntryScheme',
                        link: '/services/cies'
                    },
                    {
                        id: '26',
                        name: 'Menu.TurkeyInvestmentImmigration',
                        link: '/services/turkeyym'
                    },
                    {
                        id: '27',
                        name: 'Menu.DubaiGoldenVisa',
                        link: '/services/dubaihjqz'
                    }
                    // {
                    //     id: '23',
                    //     name: 'Menu.VanuatuPassport',
                    //     link: '/services/vanuatuym'
                    // }
                ]
            },
            {
                name: 'Menu.VisaService',
                link: '',
                isOpen: false,
                id: '29',
                children: [
                    {
                        id: '30',
                        name: 'Menu.DubaiWorkTouristVisa',
                        link: '/services/dubaiqz'
                    },
                    {
                        id: '31',
                        name: 'Menu.SingaporeWorkPermit',
                        link: '/services/singaporeqz'
                    },
                    // {
                    //     id: '27',
                    //     name: 'Menu.ThailandEliteVisa',
                    //     link: '/services/thailandqz'
                    // },
                    {
                        id: '33',
                        name: 'Menu.MauritiusSuperVisa',
                        link: '/services/mauritiusqz'
                    }
                ]
            }
        ]
    },
    {
        name: 'Menu.FinancialManagement',
        link: '',
        isOpen: false,
        id: '34',
        children: [
            {
                id: '35',
                name: 'Menu.AccountingFinancialReporting',
                link: '/services/accounting'
            },
            {
                id: '36',
                name: 'Menu.TaxAdvisoryCompliance',
                link: '/services/tax'
            }
        ]
    }
];

const formatLang = (lang: string) => {
    switch (lang) {
        case 'zh_CNS':
            return 'sc';
        case 'zh_CNT':
            return 'tc';
        case 'en_US':
            return 'en';
    }
};

const partnerList2: Array<{ href: string; img: string }> = [
    {
        href: 'https://www.facebook.com/FinovyConsulting',
        img: '/static/footer/facebook.webp'
    },
    {
        href: 'https://www.instagram.com/finovyconsulting',
        img: '/static/footer/linkedin.webp'
    },
    {
        href: 'https://www.linkedin.com/in/finovyconsulting',
        img: '/static/footer/instagram.webp'
    },
    {
        href: 'https://twitter.com/finovyconsult',
        img: '/static/footer/twitter.webp'
    }
];

const partnerList: Array<{ href: string; img: string }> = [
    {
        href: 'https://www.zhihu.com/org/finovy-visa',
        img: '/static/footer/zhihu.svg'
    },
    {
        href: 'https://mp.sohu.com/profile?xpt=ZGNhMGI3YzctN2VkMi00YTZkLWIzZWUtNmQ4YmQwYWRiNjQx',
        img: '/static/footer/sohu.svg'
    },
    {
        href: 'https://author.baidu.com/home/1715940218179418',
        img: '/static/footer/baijia.svg'
    },
    {
        href: 'https://www.toutiao.com/c/user/token/MS4wLjABAAAAh5JiNpiQ-tl9DfZC7_drXVB_tcu_bK3qEYwjNPzlZDuajN15QlQbJYkDG9pBVW-d/?source=list&log_from=fd3ee867f4766_1665568946099',
        img: '/static/footer/headlines.svg'
    }
];
interface Protocol {
    id: string;
    text: string;
    url: string;
}

const MFooterInfo: React.FC = () => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;
    const [navigateMap, setNavigateMap] = useState(FooterNavigateMap);
    const [protocolList] = useState<Protocol[]>([
        {
            id: '1',
            text: intl.get('Protocol.ServiceAgreement'),
            url: '/static/policy/2021.10.08-ProfessionalServiceAgreement.pdf'
        },
        {
            id: '2',
            text: intl.get('Protocol.MutualNDA'),
            url: '/static/policy/2021.10.08-MutualNon-DisclosureAgreement(FinovyConsulting).pdf'
        },
        {
            id: '3',
            text: intl.get('Protocol.PrivacyPolicy'),
            url: '/static/policy/Finovy-PrivacyPolicy.pdf'
        },
        {
            id: '4',
            text: intl.get('Protocol.Terms'),
            url: '/static/policy/Finovy-WebsiteTermsOfUse.pdf'
        }
    ]);
    const indexChange = (parentIndex: number, childIndex: number) => {
        const list = [...navigateMap];
        list.forEach((item, index) => {
            if (parentIndex === index) {
                item.children.forEach((item2, index2) => {
                    if (childIndex === index2) {
                        item2.isOpen = !item2.isOpen;
                    } else {
                        item2.isOpen = false;
                    }
                });
            }
        });
        setNavigateMap(list);
    };

    const listRender = () =>
        navigateMap.map((item: any, index: number) => {
            if (item.children) {
                return (
                    <div className={style.languageWrap} key={item.id}>
                        <div
                            className={classnames(style.languageBtn, item.isOpen ? style.act : null)}
                            onClick={() => {
                                const list = [...navigateMap];
                                list.forEach((item, idx) => {
                                    if (index === idx) {
                                        item.isOpen = !item.isOpen;
                                    } else {
                                        item.isOpen = false;
                                    }
                                });
                                setNavigateMap(list);
                            }}
                        >
                            <div className={style['footer-child-nav-tit']}>{intl.get(item.name) || item.name}</div>
                            <img
                                loading="lazy"
                                className={classnames(style['icon-nav-arr'], item.isOpen ? style.act : null)}
                                src={`${file_cdn}/static/footer/xiaojiantou.svg`}
                                alt=""
                            />
                        </div>
                        {item.isOpen && (
                            <div className={style.languageList}>
                                <MMenuChild
                                    subChildren={item.children}
                                    parentIndex={index}
                                    locale={formatLang(currentLocale)}
                                    indexChange={indexChange}
                                />
                            </div>
                        )}
                    </div>
                );
            }
            return (
                <div className={style.languageWrap} key={item.id}>
                    <span>
                        <Link href={item.link}>
                            <div className={style['footer-child-nav-tit']}>{intl.get(item.name) || item.name}</div>
                        </Link>
                    </span>
                </div>
            );
        });

    // const changeRouter = (lang: string) => {
    //     switch (lang) {
    //         case 'zh_CNS':
    //             return 'sc';
    //         case 'zh_CNT':
    //             return 'tc';
    //         case 'en_US':
    //             return 'en';
    //     }
    // };

    return (
        <>
            <div className={style['free-consult']} style={{ backgroundImage: `url('${file_cdn}/static/footer/free-consult-bg.jpg')` }}>
                <div className={style['free-content']}>
                    <p className={style['free-text']}>
                        {intl.get('Protocol.Expansion')}
                        <br />
                        {intl.get('Protocol.BestPartner')}
                    </p>
                    <div className={style['free-consult-btn']}>
                        <Link href="/contact">
                            <span className={style.fileInfo}>{intl.get('Protocol.FreeConsult')}</span>
                        </Link>
                    </div>
                </div>
            </div>

            <div className={style.footerContainer} style={{ backgroundImage: `url('${file_cdn}/static/footer/bg-icon-con.svg')` }}>
                <div className={style.footerInfo}>
                    <div className={style['footer-nav-fisrt']}>
                        <p className={style['footer-nav-tit']}>{intl.get('Menu.ServiceArea')}</p>
                        <div className={style['list-render-con']}>{listRender()}</div>
                    </div>
                    <div className={style['foot-nav-two']}>
                        <p className={style['footer-nav-tit']}>{intl.get('Protocol.ServiceRules')}</p>
                        <ul className={style['footer-nav-list']}>
                            <li>
                                <span
                                    className={style['nav-file-info']}
                                    onClick={() => {
                                        const tempWindow = window.open('_blank');
                                        tempWindow.location.href = '/static/policy/BankAccountList.pdf';
                                    }}
                                >
                                    {intl.get('Protocol.PayMethods')}
                                </span>
                            </li>
                            <li>
                                <Link href="/pricing">
                                    <span className={style['nav-file-info']}>{intl.get('Menu.Pricing')}</span>
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <LazyLoad>
                        <div className={style['footer-content']}>
                            <div className={style['footer-left']}>
                                <span className={style['contact-us']}>{intl.get('Menu.ContactUs')}</span>
                                <div className={style['footer-contact']}>
                                    <div className={style['code-box']}>
                                        <img loading="lazy" src={`${file_cdn}/static/footer/thePublic.jpg`} alt="" />
                                        <span>{intl.get('Protocol.OfficialPublicAccount')}</span>
                                    </div>
                                    <div className={style['code-box']}>
                                        <img loading="lazy" src={`${file_cdn}/static/footer/enterpriseWeChat.jpg`} alt="" />
                                        <span>{intl.get('Protocol.EnterpriseWeChat')}</span>
                                    </div>
                                </div>
                                <div className={style['partner-connect']}>
                                    {partnerList2.map((item) => (
                                        <img
                                            loading="lazy"
                                            key={item.img}
                                            src={`${file_cdn}${item.img}`}
                                            alt=""
                                            onClick={() => {
                                                const tempWindow = window.open('_blank');
                                                tempWindow.location.href = item.href;
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className={classnames(style['partner-connect'], style['p-m'])}>
                                    {partnerList.map((item) => (
                                        <img
                                            loading="lazy"
                                            key={item.img}
                                            src={`${file_cdn}${item.img}`}
                                            alt=""
                                            onClick={() => {
                                                const tempWindow = window.open('_blank');
                                                tempWindow.location.href = item.href;
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className={style.serviceTime}>{intl.get('Protocol.WorkingHours')}</div>
                                <div className={style.phone}>
                                    <Icon type="phone" title="phone" className={style['phone-icon']} />
                                    <span className={style['phone-text']}>{intl.get('Protocol.Telephone')}</span>
                                </div>
                                <div className={style.phone}>
                                    <Icon type="mark" title="mark" className={style['phone-icon']} />
                                    <span className={style['phone-text']}>{intl.get('Protocol.Address')}</span>
                                </div>
                            </div>
                            <div className={style['footer-logo']}>
                                <img loading="lazy" src={`${file_cdn}/static/footer/logo-white.svg`} alt="" />
                            </div>
                            <div className={style.copyRight}>
                                {intl.get('Protocol.CopyRightDesc')}
                                <br />
                                {intl.get('Protocol.CopyRight')}
                            </div>
                            <div className={style.copyInfo}>
                                {protocolList.map((item, index) => (
                                    <div key={item.id} className={style['copy-info-flex']}>
                                        {index > 0 && <span className={style['file-line']}>&ensp;|&ensp;</span>}
                                        <span
                                            className={style.fileInfo}
                                            onClick={() => {
                                                const tempWindow = window.open('_blank');
                                                tempWindow.location.href = item.url;
                                            }}
                                        >
                                            {item.text}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </LazyLoad>
                </div>
            </div>
        </>
    );
};

export default MFooterInfo;
