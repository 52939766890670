import classNames from 'classnames';
import React from 'react';
import intl from 'react-intl-universal';
import style from './index.module.less';

interface Iprops {
    mask?: boolean;
    list: Array<{
        title: string;
        desc: string;
        img: string;
    }>;
    itemStyle?: React.CSSProperties;
}

const ChooseFinovy: React.FC<Iprops> = (props: Iprops) => {
    const file_cdn = '/oss-service';
    const { mask, list, itemStyle } = props;

    return (
        <div className={style.container}>
            <div className={style['card-container']}>
                <div className={style['card-list']}>
                    {list.map((item) => (
                        <div className={classNames(style['card-item'], mask && style['card-mask'])} key={item.title} style={{ ...itemStyle }}>
                            <img loading="lazy" className={style['card-img']} src={`${file_cdn}${item.img}`} alt="" />
                            <div className={style.mask} />
                            <div className={style['title-desc']}>
                                <div className={style.title}>{intl.get(item.title) || item.title}</div>
                                <div className={style.desc}>{intl.get(item.desc) || item.desc}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

ChooseFinovy.defaultProps = {
    mask: false,
    itemStyle: {}
};

export default ChooseFinovy;
