const ExplanationType = {
    HomePage: [
        {
            title: 'Question1',
            content: 'Anwser1',
            key: '1'
        },
        {
            title: 'Question2',
            content: 'Anwser2',
            key: '2'
        },
        {
            title: 'Question3',
            content: 'Anwser3',
            key: '3'
        },
        {
            title: 'Question4',
            content: 'Anwser4',
            key: '4'
        },
        {
            title: 'Question5',
            content: 'Anwser5',
            key: '5'
        }
    ],
    OffShore: [
        {
            title: 'Question1',
            content: 'Anwser1',
            key: '1'
        },
        {
            title: 'Question2',
            content: 'Anwser2',
            key: '2'
        },
        {
            title: 'Question3',
            content: 'Anwser3',
            key: '3'
        }
    ],
    Bank: [
        {
            title: 'Question1',
            content: 'Anwser1',
            key: '1'
        },
        {
            title: 'Question2',
            content: 'Anwser2',
            key: '2'
        },
        {
            title: 'Question3',
            content: 'Anwser3',
            key: '3'
        }
    ],
    Audit: [
        {
            title: 'Question1',
            content: 'Anwser1',
            key: '1'
        },
        {
            title: 'Question2',
            content: 'Anwser2',
            key: '2'
        },
        {
            title: 'Question3',
            content: 'Anwser3',
            key: '3'
        }
    ]
};

export default ExplanationType;
