import React from 'react';
import intl from 'react-intl-universal';
import style from '../style.module.less';

const StepOne: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('Audit.RenewInfo')}</span>
        </p>
    </>
);

const StepTwo: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('Audit.PayMethods')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Audit.InternationalWireTransfer')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Audit.BankTransfer')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Audit.Paypal')}</span>
        </p>
        <p className={style.processDes}>
            <span>{intl.get('Audit.DigitalCurrency')}</span>
        </p>
    </>
);

const StepThree: React.FC<any> = () => (
    <>
        <p className={style.processDes}>
            <span>{intl.get('Audit.ReviewDocuments')}</span>
        </p>
    </>
);

export default { StepOne, StepTwo, StepThree };
