const CardShowType = {
    OffShore: [
        {
            img: 'tax',
            content: 'TaxSystem',
            key: '1'
        },
        {
            img: 'lock',
            content: 'PrivacySecurity',
            key: '2'
        },
        {
            img: 'exchange',
            content: 'ExchangeManagement',
            key: '3'
        },
        {
            icon: 'capital',
            content: 'CapitalizeStrong',
            key: '4'
        },
        {
            icon: 'global',
            content: 'TransnationalBusiness',
            key: '5'
        }
    ],
    Bank: [
        {
            icon: 'reputation',
            content: 'Reputation',
            key: '1'
        },
        {
            icon: 'security',
            content: 'PrivacySecurity',
            key: '2'
        },
        {
            icon: 'freedom',
            content: 'FreedomFund',
            key: '3'
        },
        {
            icon: 'currency',
            content: 'CurrencyMore',
            key: '4'
        },
        {
            img: 'internet',
            content: 'BankInternet',
            key: '5'
        }
    ],
    Audit: [
        {
            icon: 'submit',
            content: 'TaxReturn',
            key: '1'
        },
        {
            icon: 'borrow',
            content: 'Borrow',
            key: '2'
        },
        {
            img: 'funding',
            content: 'FundingGovernment',
            key: '3'
        },
        {
            icon: 'review',
            content: 'SafeguardInterest',
            key: '4'
        },
        {
            icon: 'inner',
            content: 'ManagementReview',
            key: '5'
        }
    ]
};

export default CardShowType;
