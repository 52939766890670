import React from 'react';
import intl from 'react-intl-universal';
import { IconFont as Icon } from '@components/Common';
import ProcessType from './processType';
import OffshoreInfo from './offShore';
import BankInfo from './bank';
import AuditInfo from './audit';
import style from './style.module.less';

interface IProps {
    pages?: string;
    advantage?: boolean;
    country?: string;
    isMobile?: boolean;
}

const Process: React.FC<IProps> = ({ pages, advantage, country, isMobile }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;
    const showInfo = (page: any, index: number) => {
        const { StepOne, StepTwo, StepThree } = page;
        switch (index) {
            case 1:
                return <StepOne /> || '';
            case 2:
                return <StepTwo /> || '';
            case 3:
                return <StepThree /> || '';
        }
    };

    const whichPage = (index: number) => {
        switch (pages) {
            case 'OffShore':
                return showInfo(OffshoreInfo, index);
            case 'Bank':
                return showInfo(BankInfo, index);
            case 'Audit':
                return showInfo(AuditInfo, index);
            default:
                return showInfo(OffshoreInfo, index);
        }
    };

    return (
        <div className={isMobile ? [style.process, style.mobileProcess].join(' ') : style.process}>
            <div className={style.processContent}>
                {advantage ? (
                    <h1>
                        {intl.get(`Country.${country}`)}
                        {currentLocale === 'en_US' && ' '}
                        {intl.get('Advantage.RegistrationProcess')}
                    </h1>
                ) : (
                    <h1>{intl.get(`${pages}.RegistrationProcess`)}</h1>
                )}
                <div className={style.processDetail}>
                    {ProcessType[pages].map((item: any, index: number) => (
                        <div className={[`${style.stepShow}`, index > 0 && `${style.notOne}`].join(' ')} key={item.key}>
                            <div className={style.stepIcon}>
                                <Icon type={item.icon} />
                                {item.splitLine && <span className={style.splitLine} />}
                                {isMobile && <h3>{intl.get(`${pages}.${item.title}`)}</h3>}
                            </div>
                            {!isMobile && <h3>{intl.get(`${pages}.${item.title}`)}</h3>}
                            {index === 0 ? (
                                <>
                                    <p className={`${style.processDes} ${style.phone}`}>
                                        <span>{intl.get('Login.Phone')}：</span>
                                        <span>{intl.get('Protocol.Telephone')}</span>
                                    </p>
                                    <p className={`${style.processDes} ${style.email}`}>
                                        <span>{intl.get('Login.FormEmail')}：</span>
                                        <span>{intl.get('Contact.BannerDescEmail')}</span>
                                    </p>
                                </>
                            ) : (
                                whichPage(index)
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Process.defaultProps = {
    pages: 'OffShore',
    advantage: false,
    country: 'BritishVirgin',
    isMobile: false
};

export default Process;
