import React from 'react';
import classnames from 'classnames';
import style from './index.module.less';

interface IProps {
    isDescContentBold?: boolean;
    descContent?: any;
    title: any;
    type?: 'center' | 'left' | 'right';
    label?: any;
}
const Titlecom: React.FC<IProps> = (props: IProps) => {
    const { title, descContent, type, label, isDescContentBold } = props;
    return (
        <div
            className={classnames(
                style['title-box'],
                type === 'center' ? style['title-box-center'] : type === 'left' ? style['title-box-left'] : style['title-box-right'],
                !!label && style['title-box-label']
            )}
        >
            <p className={style.title}>{title}</p>
            <p
                className={classnames(
                    style['tit-bg'],
                    type === 'center' ? style['tit-bg-center'] : type === 'left' ? style['tit-bg-left'] : style['tit-bg-right'],
                    isDescContentBold && style['tit-bold']
                )}
            >
                {descContent}
            </p>
            {!!label && <p className={classnames(style['tit-small'])}>{label}</p>}
        </div>
    );
};
Titlecom.defaultProps = {
    isDescContentBold: false,
    descContent: '',
    type: 'left',
    label: ''
};
export default Titlecom;
