import React, { useEffect, ReactNode } from 'react';
import intl from 'react-intl-universal';
import Head from 'next/head';
import { Layout } from 'antd';
import Menu from '../Menu';
import FooterInfo from '../Footer';
import MFooterInfo from '../../MCommon/MFooter';
import MMenu from '../../MCommon/MMenu';

const { Header, Footer: FooterContainer, Content } = Layout;

type Pages = string;

interface LayoutProps {
    children?: ReactNode;
    pages?: Pages;
    isMobile?: boolean;
    keywords?: string;
    description?: string;
    title?: string;
}

const Layouts: React.FC<LayoutProps> = ({ children, pages, isMobile, keywords, description, title }: LayoutProps) => {
    const file_cdn = '/oss-service';
    const {
        options: { currentLocale }
    } = intl as any;

    /**
     * 处理域名 /sc，使用 next 内置 router 无法获取到该域，因此使用 window.location
     */
    useEffect(() => {
        if (window && window.location.pathname === '/sc') history.pushState(null, null, '/');
    }, []);

    const getAnalyticsTag = () => ({
        __html: `
                var _hmt = _hmt || [];
                (function() {
                var hm = document.createElement("script");
                hm.defer = "true";
                hm.src = "https://hm.baidu.com/hm.js?7b95d906b8c9ed53f507a9a315844e8b";
                var s = document.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(hm, s);
                })();
            `
    });

    return (
        <>
            <Head>
                <meta name="viewport" content="initial-scale=1.0, minimal-ui, width=device-width, shrink-to-fit=no" />
                <meta charSet="utf-8" />
                <meta name="keywords" content={keywords || intl.get(`${pages}.KeyWords`)} />
                <meta name="description" content={description || intl.get(`${pages}.Description`)} />
                <meta name="robots" content="index, follow" />
                <link rel="icon" href={`${file_cdn}/static/icon/logo-finovy.png`} />
                <title>{title || intl.get(`${pages}.Title`)}</title>
                <script dangerouslySetInnerHTML={getAnalyticsTag()} />
            </Head>
            <section className={isMobile ? 'mobile' : 'pcStyle'}>
                <Layout className={[currentLocale].join(' ')} style={{ paddingTop: !isMobile ? '100px' : 0 }}>
                    <Header>{isMobile ? <MMenu /> : <Menu isMobile={isMobile} />}</Header>
                    <Content>{children}</Content>
                    <FooterContainer>{isMobile ? <MFooterInfo /> : <FooterInfo />}</FooterContainer>
                </Layout>
            </section>
        </>
    );
};

Layouts.defaultProps = {
    children: null,
    pages: 'HomePage',
    isMobile: false,
    keywords: undefined,
    description: undefined,
    title: undefined
};

export default Layouts;
