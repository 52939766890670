import React from 'react';
import { Collapse } from 'antd';
import intl from 'react-intl-universal';
import Link from 'next/link';
import Icon from '@components/Common/IconFont';
import ExplanationType from './explanationType';
import style from './style.module.less';

const { Panel } = Collapse;

interface IProps {
    pages?: string;
    isMobile: boolean;
}

const Explanation: React.FC<any> = ({ pages, isMobile }: IProps) => {
    const {
        options: { currentLocale }
    } = intl as any;
    return (
        <div
            className={
                isMobile && pages !== 'HomePage'
                    ? [style.explanationContainer, style.mobileExContainer].join(' ')
                    : isMobile
                    ? [style.explanationContainer, style.mobileExContainer, style.bgColor, style.mobileBgColor].join(' ')
                    : pages !== 'HomePage'
                    ? style.explanationContainer
                    : [style.explanationContainer, style.bgColor].join(' ')
            }
        >
            <div lang={currentLocale} className={style.explanationContent}>
                <h1>FAQ</h1>
                <Collapse
                    expandIconPosition="right"
                    destroyInactivePanel={false}
                    expandIcon={({ isActive }) =>
                        isActive ? (
                            <div>
                                <Icon type="close" />
                            </div>
                        ) : (
                            <div>
                                <Icon type="add" />
                            </div>
                        )
                    }
                >
                    {ExplanationType[pages].map((item: any) => (
                        <Panel header={intl.get(`${pages}.${item.title}`)} key={item.key} forceRender={false}>
                            <p className={style.question}>{intl.get(`${pages}.${item.content}`)}</p>
                            {pages === 'HomePage' && (
                                <Link href="/contact">
                                    <div className={style.detail}>
                                        <span>{intl.get('HomePage.LearnMore')}</span>
                                        <Icon type="arrow" className={style.arrow} />
                                    </div>
                                </Link>
                            )}
                        </Panel>
                    ))}
                </Collapse>
            </div>
        </div>
    );
};

Explanation.defaultProps = {
    pages: 'HomePage'
};

export default Explanation;
